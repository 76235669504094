import { Link } from 'react-router-dom'

export const Footer = () => {

    return (
        <div className="footer"> 
            <div className="footer__wrapper">
                <p className="footer__text">Чтобы добавить свой канал/чат/страничку <Link to='/sendmail' className="footer__add"> заполните форму
                </Link> </p>
            </div>
        </div>
    )
}