import { Link, useNavigate } from 'react-router-dom'
import { SearchForm } from './SearchForm'
import { useDispatch } from 'react-redux'
import { linksRequest, tableAllRequest } from '../store/actions/linksActions'

export const Header = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
        
    const getAllChatsLink = async () => {
      await dispatch(linksRequest())
      navigate(`/links`)
    }
    const getAllTablesLink = async () => {
        await dispatch(tableAllRequest())
        navigate(`/tables`)
      }
    
    return (
        <div className="header"> 
            <div className="header__wrapper">
                <div className="header__common">
                <Link to={'/'}><p>Сербия / Белград</p></Link>
                <Link to={'/sendMail'}><p>Контакты</p></Link>
                    <SearchForm />
                </div>
                <div className="header__filter">
                    <Link to={'/'} className="header__item">Чаты по категориям</Link>
                    <Link to={'/links'} className="header__item" onClick={getAllChatsLink}>Чаты списком</Link>
                    <Link to={'/tables'} className="header__item" onClick={getAllTablesLink}>Таблицы</Link>
                    <Link to={'/person'} className="header__item">Персональные страницы</Link>
                </div>
            </div>
            
            
        </div>
    )
}