import { Link } from 'react-router-dom'
import uniqid from 'uniqid'
import { groupsPerson } from '../helpers/group'
import { GroupsOfPerson } from './GroupsOfPerson'
import { BtnScrollUp } from '../helpers/scrollUp'

export const Person = () => {

   

  
    return (
        <div className="main__wrapper">
            <h4 className="attention">Эта часть ресурса сейчас в разработке. Если вы хотите видеть свою с траничку с услугами здесь, заполните <Link to='/sendmail'><span className="main__all-links">форму</span></Link></h4>

            <div className="main__list">
               {groupsPerson.map((group) => <GroupsOfPerson key={uniqid()} group={group} />)}
            </div>
            <BtnScrollUp />
        </div>
    )
    

}