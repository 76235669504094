import  { useState, useEffect } from 'react'

export const BtnScrollUp = () => {

    const [btn, setBtn] = useState(false)

    useEffect(()=> {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 70) {
                setBtn(true)
            } else {
                setBtn(false) 
            }
        })
    },[])

        const scrollUp = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
      return (
        // <div className="">
            <>{btn && (<button 
            className='scroll-btn'
            onClick={scrollUp}
            >
                &#8593;
            </button>)} </>
        // </div>
        
      );
}