import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const NotFound = () => {

   const navigate = useNavigate();
   useEffect(() => {
      const timeout = setTimeout(() => {navigate("/")}, 2000)
      return () => clearTimeout(timeout)
  })

 return <div className="not-found__wrapper">
    <h2>Извините, страница не найдена</h2>
 </div>
}