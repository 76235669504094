import ReactLoading from "react-loading";


export const Loading = () => {


    return (
        <div className="tables__wrapper">
            {/* <h1>Loading...</h1> */}
            <ReactLoading type="spokes" color="#1E6140"
        height={100} width={50} />
        </div>
    )

}