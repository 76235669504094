import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BtnScrollUp } from '../helpers/scrollUp'
import { FaClosedCaptioning, FaPhoneAlt } from 'react-icons/fa'


export const ListOfLinksPerson = () => {

    const [open, setOpen] = useState(false)
    const [open1, setOpen1] = useState(false)
    const [open2, setOpen2] = useState(false)
    useEffect(() => {

    },[open, open1, open2])

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen1 = () => {
        setOpen1(true)
    }
    const handleClose1 = () => {
        setOpen1(false)
    }

    const handleOpen2 = () => {
        setOpen2(true)
    }
    const handleClose2 = () => {
        setOpen2(false)
    }


    return (
        <div className="person__wrapper">
            <h4 className="attention">Эта часть ресурса сейчас в разработке. Если вы хотите видеть свою с траничку с услугами здесь, заполните <Link to='/sendmail'><span className="main__all-links">форму</span></Link></h4>
            <BtnScrollUp />
            <div className="person">
                <div className="person__item" onClick={handleOpen1}>
                    <h3 className="person__name">Школа плавания SWIMYOU</h3>
                    <p className="person__shortInfo">Групповые и индивидуальные занятия для детей и взрослых</p>
                    <p className="person__location">Локация: <br />
                        <span > СК «Вождовац», бассейн «Ташмайдан», бассейн международной школы BROOKHILL</span> </p>
                    <p className="person__more" onClick={handleOpen1}>Подробнее</p>
                </div>
                <div className="person__item" onClick={handleOpen2}>
                    <h3 className="person__name">Капоэйра в Сербии</h3>
                    <p className="person__shortInfo">Капоэйра для взрослых и детей в Белграде и Нови саде </p>
                    <p className="person__location">Локация: <br />
                        <span > Белград: Деспота Стефана 68а; Народных Героя 41</span> <br />
                        Нови сад: Милетича 28
                        </p>
                    <p className="person__more" onClick={handleOpen2}>Подробнее</p>
                </div>
                <div className="person__item" onClick={handleOpen}>
                    <h3 className="person__name">Евгения</h3>
                    <p className="person__shortInfo">Стилист, визажист, ресницы, брови, перманент  </p>
                    <p className="person__location">Локация:
                        <span >Врачар</span> </p>
                    <p className="person__more" onClick={handleOpen}>Подробнее</p>
                </div>

                                

            </div>
            {open ? 
            <>
                <div className="person__modal">
                    <div className="person__itemModel">
                        <p className="person__close" onClick={handleClose}>
                        <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                        </p>
                        <h3 className="person__name">Евгения</h3>
                        <p className="person__description">
                    
                         - loremCulpa dolore incididunt ipsum incididunt labore commodo reprehenderit cupidatat enim.  <br />
                        - Deserunt id adipisicing consectetur exercitation tempor sunt nisi elit occaecat voluptate nisi dolor fugiat veniam.  <br />
                        - Aute consectetur mollit ad sit duis et velit tempor do enim.  <br />
                        - Adipisicing deserunt pariatur amet consectetur voluptate sunt consequat non magna nisi incididunt proident minim.  <br />
                        - Nisi incididunt magna sit do incididunt Lorem velit tempor exercitation.<br />
                        - Elit nisi do labore excepteur ea.  </p>
                        <p className="person__location">Локация/Адрес: 
                            <span > Врачар, </span> 
                            Mutapova 30a <br />
                            <a href="https://goo.gl/maps/qZmM66czbA24RMe59" target="_blank" rel="noreferrer">Посмотреть на карте</a>
                            </p>
                        <div className="person__social">
                            
                            <div className="social facebook" title="facebook">
                                <a href="#" target="_blank" rel="noreferrer">
                                    <svg role="img" viewBox="0 0 320 512">
                                        <path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/>
                                    </svg>
                                </a>
                            </div>
                            <div className="social instagram">
                                <a href="#" target="_blank" title="instagram" rel="noreferrer">
                                    <svg role="img" viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
                                    </svg>
                                </a>
                            </div>
                            <div className="social telegram" title="telegram">
                                <a href="#" target="_blank" rel="noreferrer">
                                    <svg role="img" viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"/>
                                    </svg>
                                </a>
                            </div>
                            <div className="social viber" title="viber">
                                <a href="#" target="_blank" rel="noreferrer">
                                    <svg role="img" viewBox="0 0 512 512">
                                        <path fill="currentColor" d="M444 49.9C431.3 38.2 379.9.9 265.3.4c0 0-135.1-8.1-200.9 52.3C27.8 89.3 14.9 143 13.5 209.5c-1.4 66.5-3.1 191.1 117 224.9h.1l-.1 51.6s-.8 20.9 13 25.1c16.6 5.2 26.4-10.7 42.3-27.8 8.7-9.4 20.7-23.2 29.8-33.7 82.2 6.9 145.3-8.9 152.5-11.2 16.6-5.4 110.5-17.4 125.7-142 15.8-128.6-7.6-209.8-49.8-246.5zM457.9 287c-12.9 104-89 110.6-103 115.1-6 1.9-61.5 15.7-131.2 11.2 0 0-52 62.7-68.2 79-5.3 5.3-11.1 4.8-11-5.7 0-6.9.4-85.7.4-85.7-.1 0-.1 0 0 0-101.8-28.2-95.8-134.3-94.7-189.8 1.1-55.5 11.6-101 42.6-131.6 55.7-50.5 170.4-43 170.4-43 96.9.4 143.3 29.6 154.1 39.4 35.7 30.6 53.9 103.8 40.6 211.1zm-139-80.8c.4 8.6-12.5 9.2-12.9.6-1.1-22-11.4-32.7-32.6-33.9-8.6-.5-7.8-13.4.7-12.9 27.9 1.5 43.4 17.5 44.8 46.2zm20.3 11.3c1-42.4-25.5-75.6-75.8-79.3-8.5-.6-7.6-13.5.9-12.9 58 4.2 88.9 44.1 87.8 92.5-.1 8.6-13.1 8.2-12.9-.3zm47 13.4c.1 8.6-12.9 8.7-12.9.1-.6-81.5-54.9-125.9-120.8-126.4-8.5-.1-8.5-12.9 0-12.9 73.7.5 133 51.4 133.7 139.2zM374.9 329v.2c-10.8 19-31 40-51.8 33.3l-.2-.3c-21.1-5.9-70.8-31.5-102.2-56.5-16.2-12.8-31-27.9-42.4-42.4-10.3-12.9-20.7-28.2-30.8-46.6-21.3-38.5-26-55.7-26-55.7-6.7-20.8 14.2-41 33.3-51.8h.2c9.2-4.8 18-3.2 23.9 3.9 0 0 12.4 14.8 17.7 22.1 5 6.8 11.7 17.7 15.2 23.8 6.1 10.9 2.3 22-3.7 26.6l-12 9.6c-6.1 4.9-5.3 14-5.3 14s17.8 67.3 84.3 84.3c0 0 9.1.8 14-5.3l9.6-12c4.6-6 15.7-9.8 26.6-3.7 14.7 8.3 33.4 21.2 45.8 32.9 7 5.7 8.6 14.4 3.8 23.6z"/>
                                    </svg>
                                </a>
                            </div>
                            <div className="social phone" title="viber">
                                <a href="#" target="_blank" rel="noreferrer">
                                <FaPhoneAlt />  
                                </a>
                            </div>

                        </div>   
                        
                        
                    </div>
                </div>
            </>
            : null}




{open1 ? 
            <>
                <div className="person__modal">
                    <div className="person__itemModel">
                        <p className="person__close" onClick={handleClose1}>
                        <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                        </p>
                        <h3 className="person__name">Школа плавания SWIMYOU</h3>
                        <p className="person__description">
                    
                         - Обучение и совершенствование.  <br />
                        - Групповые занятия для детей от 3 лет  <br />
                        - Индивидуальные занятия для детей и взрослых  <br />
                        - Стоимость от 1000 rsd и зависит от количества занятий в неделю.  <br />
                        - Наш сайт:  <a href='www.swimyou.club' target="_blank" rel="noreferrer" className='social-link'>www.swimyou.club</a><br />
                        - По всем вопросам можно обращаться <a className='social-link' href='https://t.me/swimyou' target="_blank" rel="noreferrer">в телеграмме</a>  или по почте swimyou@yandex.ru</p>
                        <p className="person__location">Локация/Адрес: 
                            <span > </span> 
                            СК «Вождовац», бассейн «Ташмайдан», бассейн международной школы BROOKHILL<br />
                        </p>
                        <div className="person__social">
                            
                            
                            <div className="social instagram">
                                <a href="https://www.instagram.com/swimyou.club/" target="_blank" title="instagram" rel="noreferrer">
                                    <svg role="img" viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
                                    </svg>
                                </a>
                            </div>
                            <div className="social telegram" title="telegram">
                                <a href="https://t.me/swimyou_serbja" target="_blank" rel="noreferrer">
                                    <svg role="img" viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"/>
                                    </svg>
                                </a>
                            </div>
                            
                            <div className="social phone" title="viber">
                                <span className='tel'>
                                <FaPhoneAlt />  
                                <span className='number'>+381611867725</span>
                                </span>
                            </div>

                        </div>   
                        
                        
                    </div>
                </div>
            </>
            : null}

{open2 ? 
            <>
                <div className="person__modal">
                    <div className="person__itemModel">
                        <p className="person__close" onClick={handleClose2}>
                        <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                        </p>
                        <h3 className="person__name">Капоэйра в Сербии</h3>
                        <p className="person__description">
                    
                         - Обучение и совершенствование.  <br />
                        - Групповые занятия для детей от 3 лет  <br />
                        {/* - Индивидуальные занятия для детей и взрослых  <br /> */}
                        {/* - Стоимость от 1000 rsd и зависит от количества занятий в неделю.  <br /> */}
                        {/* - Наш сайт:  www.swimyou.club<br /> */}
                        - По всем вопросам можно обращаться <a className='social-link' target="_blank" rel="noreferrer" href='https://t.me/dimasiki_spb'>в телеграмме</a>  </p>
                        <p className="person__location">Локация/Адрес: 
                            <span > </span> 
                            Белград: Деспота Стефана 68а; Народных Героя 41 <br />Нови сад: Милетича 28<br />
                        </p>
                        <div className="person__social">
                            
                            
                            <div className="social instagram">
                                <a href="https://www.instagram.com//capoeira.srb/" target="_blank" title="instagram" rel="noreferrer">
                                    <svg role="img" viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
                                    </svg>
                                </a>
                            </div>
                            <div className="social telegram" title="telegram">
                                <a href="https://t.me/capoeira_srb" target="_blank" rel="noreferrer">
                                    <svg role="img" viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"/>
                                    </svg>
                                </a>
                            </div>
                            
                            {/* <div className="social phone" title="viber">
                                <span className='tel'>
                                <FaPhoneAlt />  
                                <span className='number'>+381611867725</span>
                                </span>
                            </div> */}

                        </div>   
                        
                        
                    </div>
                </div>
            </>
            : null}



        </div>
    
    )
}