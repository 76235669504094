import { shallowEqual, useSelector } from 'react-redux'
import { getTables } from '../store/selectors/linksSelectors'
import uniqid from 'uniqid'
import { BtnScrollUp } from '../helpers/scrollUp'
import { useEffect } from 'react'
import {  useNavigate } from 'react-router-dom'
import { getError, getLoading } from "../store/selectors/commonSelectors";
import { Loading } from '../helpers/loader'

export const Tables = () => {

    const navigate = useNavigate()

    const error = useSelector(getError)
    const loading = useSelector(getLoading)

    let tables = useSelector(getTables, shallowEqual)


    useEffect(() => {
        if (error) navigate(`/404`)
    }, [error])

    useEffect(() => {
        if (!tables.length && loading === "idle") navigate(`/`)
    }, [tables.length, loading])


    const openTable = (url) => {
        window.open(url, '_blank', 'noreferrer')
    }

    return (
        <div className="tables__wrapper">
            {loading  === "pending"
            ? <Loading />
            : error
                ? null
                : tables.length
                ? <>
                    <div className="tables">
                        {tables.map(table =>  
                            <div className="tables__item" key={uniqid()} onClick={()=>openTable(table.link)}>
                                <a 
                                    href={table.link} 
                                    target="_blank" 
                                    rel="noreferrer"
                                    className="tables__link"
                                >
                                    {table.name} 
                                </a>
                                <p className="tables__description">{table.description}</p>
                            </div>
                        
                        )}
                    </div> 
            
                </>
            :null
        }
        <BtnScrollUp />
        </div>
    )

}