import { Route, Routes } from 'react-router-dom'
import { MainPage } from './components/MainPage/MainPage'
import { Header } from './components/Header'
import { ListOfLinks } from './components/ListOfLinks'
import { Footer } from './components/Footer'
import { SendMail } from './components/SendMail'
import { Person } from './components/Person'
import { ListOfLinksPerson } from './components/ListOfLinksPerson'
import { NotFound } from './components/404'
import './App.scss'
import { Tables } from './components/Tables'

function App() {



  return (
    <div className='App'>
      <Header />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/links" element={<ListOfLinks />} />
        <Route path="/group/:group" element={<ListOfLinks />} />
        <Route path="/tables" element={<Tables />} />
        <Route path="/person" element={<Person />} />
        <Route path="/person/:group" element={<ListOfLinksPerson />} />
        <Route path="/sendmail" element={<SendMail />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
