import { useState, useEffect } from 'react'
import { postData } from '../helpers/dbRequest'
import { Error } from '../helpers/error'
import { BtnScrollUp } from '../helpers/scrollUp'
import { serverhost } from '../helpers/vars'


export const SendMail = () => {

    const [form, setForm] = useState({
        subject: '', info: '', contacts:''
      })

    const [error, setError] = useState("")

    useEffect(() => {
        const timeout = setTimeout(() => setError(""), 2000)
        return () => clearTimeout(timeout)
    }, [error])

    const changeHandler = event => {
        setForm({ ...form, [event.target.name]: event.target.value })
      }
    const sendHandler = async () => {
        if (form.subject.trim() === '') {
            setError('Напишите тему сообщения')
            return
        }
        if (form.info.trim() === '') {
            setError('Заполните поле "Информация"')
            return
        }
        try {
            const data = await postData(`${serverhost}/api/mail/send`, {...form})
            setForm({subject: '', info: '',  contacts:''})
            setError(data.message)
        }
        catch (e) {}
    }

    return (
        <div className="sendMail__wrapper">
          <BtnScrollUp />
          
          <div>
            <p>Информационные ресурсы представлены для города Белграда</p>
            <p>Добавление информации на сайт БЕСПЛАТНО. Сайт - это место, где собраны ссылки на ресурсы с возможностью поиска необходимых данных</p>
            <p>Вопросы и предложения можно отправить заполнив форму ниже. Если хотите получить ответ, обязательно укажите Ваши контактные данные</p>
            <p>Для добавления чата/таблицы необходимы: ссылка, название, краткое описание </p>
            <p>Для добавления личной страницы необходимо:</p>
             <ul>
                <li>Ваше имя/название организации;</li>
                <li>краткое описание (теги для поиска);</li>
                <li>подробное описание (оказываемые услуги, цены и т.д. по Вашему желанию);</li>
                <li>локация (район), где Вы находитесь;</li>
                <li>точный адрес (или ссылка на Ваш адрес на карте);</li>
                <li>контакты, по которым с Вами можно свзяться (соц.сети, телефон и т.д. для Вашей карточки услуги)</li>
             </ul>
          </div>
            <div className="sendMail__field">
                <label htmlFor="subject" className="sendMail__label">Тема сообщения 
                <span className="sendMail__span">*</span>
                </label> 
                <input
                  placeholder="Тема сообщения"
                  id="email"
                  type="text"
                  name="subject"
                  className="sendMail__input"
                  value={form.subject}
                  onChange={changeHandler}
                  required={true}
                />
            </div>
            <div className="sendMail__field">
              <label htmlFor="textarea" className="sendMail__label">Информация 
              <span className="sendMail__span">*</span>
              </label>
                <textarea 
                  placeholder="Ссылка и название чата/группы/странички"
                  name="info" 
                  rows="10" 
                  cols="50"
                  value={form.info}
                  onChange={changeHandler}
                  required={true}
                  className="sendMail__input"
                ></textarea>
            </div>
            <div className="sendMail__field">
              <label htmlFor="textarea" className="sendMail__label">Ваши контактные данные (необязательно, но если ждете ответ, то укажите)</label>
                <textarea 
                  placeholder="Ваша почта, тг, инстаграмм"
                  name="contacts" 
                  rows="10" 
                  cols="50"
                  value={form.contacts}
                  onChange={changeHandler}
                  required={true}
                  className="sendMail__input"
                ></textarea>
            </div>
            <button onClick={sendHandler} className="sendMail__button">Отправить</button>
                {error && <Error error={error}/>}
        </div>
    )
}