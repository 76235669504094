import { CHATS_SEARCH, TABLES_SEARCH } from '../types/types'
import { postData, getData } from '../../helpers/dbRequest'
import { serverhost } from '../../helpers/vars'
import { setLoading, setError, setSuccess } from "./commonActions";


export const chatSearch = (data) => ({
    type: CHATS_SEARCH,
    data
})
export const tableSearch = (data) => ({
    type: TABLES_SEARCH,
    data
})

export const linksRequest = () => async (dispatch) => {
    try {
        dispatch(setLoading())
        const links = await postData(`${serverhost}/api/chats/getlinks`)
        dispatch(chatSearch(links))
        dispatch(setSuccess())

    }
    catch (error) {
        console.log(error)
        dispatch(setError(error))
    }
}
//   /api/chats/
export const groupRequest = (groupSearch) => async (dispatch) => {
    try {
        dispatch(setLoading())
        const links = await postData(`${serverhost}/api/chats/grouplinks`,{groupSearch})
        dispatch(chatSearch(links))
        dispatch(setSuccess())
    }
    catch (error) {
        console.log(error)
        dispatch(setError(error))
    }
}

export const linksAllRequest = () => async (dispatch) => {
    try {
        dispatch(setLoading());

        const links = await getData(`${serverhost}/api/chats/getlinks`)
        dispatch(chatSearch(links))
        dispatch(setSuccess())
    }
    catch (error) {
        console.log(error)
        dispatch(setError(error))
    }
}
export const searchRequest = (search) => async (dispatch) => {
    try {
        dispatch(setLoading())
        const links = await postData (`${serverhost}/api/chats/searchlinks`, {search})
        dispatch(chatSearch(links))
        dispatch(setSuccess())
    }
    catch (error) {
        console.log(error)
        dispatch(setError(error))
    }
}
//   /api/tables/
export const tableAllRequest = () => async (dispatch) => {
    try {
        dispatch(setLoading())
        const links = await postData (`${serverhost}/api/tables/getlinks`)
        dispatch(tableSearch(links))
        dispatch(setSuccess())
    }
    catch (error) {
        console.log(error)
        dispatch(setError(error))
    }
}


