import { groupRequest } from '../../store/actions/linksActions'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'


export const GroupsOfChats = ({ group }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSearchGroup = async () => {
       await dispatch(groupRequest(group.value))
        navigate(`/group/${group.value}`);
    }
    
    return (
        
        <div 
            className="main__group-item" 
            to={`/group/${group.value}`} 
            onClick={handleSearchGroup}
        >
            <p>{group.label}</p>
        </div>
    )
}
