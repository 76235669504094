import  { useState } from 'react'
import { searchRequest } from '../store/actions/linksActions'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

export const SearchForm = () => {

    
    const [search, setSearch] = useState('')
    const handleSearch = (e) => {
        setSearch(e.target.value)
    }
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()


    const handleSubmit = async(e) => {
        e.preventDefault()

       await dispatch(searchRequest(search));

        if (location.pathname !== '/links') {
            navigate("/links")
        }
        setSearch('')
        
    }

    return (
        <div>
            <form onSubmit={handleSubmit} className="header__search">
                <input className="input-search" value={search} onChange={handleSearch} placeholder="Поиск" /> 
                <span className="icon-search" onClick={handleSubmit}>  <i className="fa fa-search" aria-hidden="true"></i> </span>
               
            </form>
        </div>
    )
}