import {
    CHATS_SEARCH,
    TABLES_SEARCH,
} from '../types/types'

const initialState = {
    chats: [],
    tables: []
}

export const linksReducer = (state = initialState, { type, data }) => {
    switch (type) {
        case CHATS_SEARCH:
            return {
                ...state,
                chats: data,
            }
        case TABLES_SEARCH:
            return {
                ...state,
                tables: data,
            }
        default:
            return state
    }
}