import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { linksReducer } from './reducers/linksReducer';
import { commonReducer } from './reducers/commonReducer';

const rootReducer = combineReducers({
    search: linksReducer,
    common: commonReducer,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
)