
export const groupsChat = [
    {label:'Дети', value:'child'},
    {label:'Детские клубы / Секции', value:'childTime'}, 
    {label:'Купить / Продать / Обмен', value:'buy'}, 
    {label:'Спорт / Фитнес', value:'sport'},
    {label:'Книги', value:'books'}, 
    {label:'Релокация / Сербия / Визаран', value:'relocation'},
    {label:'Родители / Школы / Сады / Няни / ВУЗы', value:'parent'}, 
    {label:'Медицина / Врачи', value:'doctors'},
    {label:'Лекарства', value:'medicament'}, 
    {label:'Чаты районов', value:'chats'},  
    {label:'Животные', value:'animals'}, 
    {label:'Специалисты / Салоны / Магазины / Сервисы', value:'specialist'}, 
    {label:'Женский клуб', value:'women'},  
    {label:'Мужской клуб', value:'men'},
    {label:'Иностранный язык', value:'foreign'}, 
    {label:'Гастрономия / Кулинария', value:'cooking'}, 
    {label:'Музыка', value:'music'},
    {label:'Блоги / Каналы', value:'chanal'}, 
    {label:'Строительство и ремонт', value:'renovation'},
    {label:'Авто / Мото', value:'avto'}, 
    {label:'Новости / Мероприятия / Встречи', value:'news'},
    {label:'Работа / IT', value:'work'}, 
    {label:'Рукоделие', value:'handmade'}, 
    {label:'Жилье: покупка / продажа / аренда', value:'rent'}, 
    {label:'Перевозка вещей / Покупки в других странах', value:'transportation'},
    {label:'Финансы', value:'finance'},
    {label:'Досуг / Хобби / Клубы по интересам', value:'fineTime'},
    {label:'Разное', value:'different'},
    {label:'Бизнес', value:'business'},
    {label:'Психология', value:'psychology'},
    
]

export const groupsPerson = [
    {label:'Спорт / фитнес', value:'sport'},
    {label:'Иностранные языки', value:'foreign'},
    {label:'Репетиторы', value:'tutor'},
    {label:'Клининг', value:'cleaning'},
    {label:'Музыка / Вокал', value:'music'},
    {label:'Специалисты / Мастер на час', value:'specialist'},
    {label:'Маникюр / Педикюр ', value:'manicure'},
    {label:'Косметология / Массаж', value:'Косметология'},
    {label:'Эпиляция', value:'epilation'},
    {label:'Парикмахер / Барбер', value:'barber'},
    {label:'Стилист / Визажист', value:'stylist'},
    {label:'Тату', value:'tattoo'},
    {label:'IT / SMM / Дизайн', value:'design'},
    {label:'Ремонт и пошив одежды', value:'clothes'},
    {label:'Строительство и ремонт', value:'renovation'},
    {label:'Для животных', value:'animals'},
    {label:'Медицина', value:'medicine'},
    {label:'Аниматоры', value:'animator'},
    {label:'Риэлторы', value:'rent'},
    {label:'Финансы', value:'finance'},
    {label:'Фото / Видео', value:'foto'},
        

]

