export const CHATS_SEARCH = 'CHATS_SEARCH'
export const TABLES_SEARCH = 'TABLES_SEARCH'
export const LOADING = "LOADING";
export const ERROR = "ERROR";
export const SUCCESS_LOADING = "SUCCESS_LOADING";
export const LOADING_STATUS = {
    IDLE: "idle",
    SUCCESS: "success",
    ERROR: "error",
    PENDING: "pending"
}