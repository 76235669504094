import { useNavigate } from 'react-router-dom'


export const GroupsOfPerson = ({ group }) => {

    const navigate = useNavigate();

    const handleSearchGroup = () => {
        navigate(`/person/${group.value}`);
    }
    
    return (
        
    <div 
        className="main__group-item" 
        to={`/person/${group.value}`} 
        onClick={handleSearchGroup}
    >
         <p>{group.label}</p>
    </div>
    )
}
