import { shallowEqual, useSelector } from 'react-redux'
import { getChats } from '../store/selectors/linksSelectors'
import uniqid from "uniqid";
import { BtnScrollUp } from '../helpers/scrollUp'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { getError, getLoading } from "../store/selectors/commonSelectors";
import { Loading } from '../helpers/loader'



export const ListOfLinks = () => {

    const error = useSelector(getError)
    const loading = useSelector(getLoading)
    const navigate = useNavigate()


    let chats = useSelector(getChats, shallowEqual)

    useEffect(() => {
        if (error) navigate(`/404`)
    }, [error])

    useEffect(() => {
        if (!chats.length && loading === "idle") navigate(`/`)
    }, [chats.length, loading])


    return (
        <div className="group-links group-links__wrapper">
            {loading  === "pending"
            ? <Loading />
            : error
                ? null
                : chats.length
                ? <>
                    {chats.map(chat =>  <a 
                        href={chat.link} 
                        target="_blank" 
                        key={uniqid()} 
                        className="group-links__link" 
                        rel="noreferrer"> 
                        {chat.name} 
                    </a> )}
                    </>
                :null
            }
            <BtnScrollUp />
        </div>
    
    )
}