import uniqid from 'uniqid'
import { GroupsOfChats } from './GroupsOfChats';
import { linksRequest } from '../../store/actions/linksActions'
import { useDispatch } from 'react-redux'
import { groupsChat } from '../../helpers/group'
import { Link } from 'react-router-dom'
import { BtnScrollUp } from '../../helpers/scrollUp'
import { useNavigate } from 'react-router-dom';



export const MainPage =  () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const getAllChatsLink = async () => {
       await dispatch(linksRequest());
       navigate(`/links`)
    }

   
    return (
        <div className="main__wrapper">
            <h1 className="main__tittle">Чаты по категориям</h1>
            <h4 className="main__subtittle">Посмотреть все чаты одним списком можно <Link to={'/links'} className="main__all-links" onClick={getAllChatsLink}>здесь</Link></h4>
            <div className="main__list">
               {groupsChat.map((group) => <GroupsOfChats key={uniqid()} group={group} />)}
            </div>
            <BtnScrollUp />
        </div>
    )
}